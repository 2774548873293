import { useTheme } from '@emotion/react';
import {
  Box, Stack, Typography, IconButton, Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  ApiResponse,
  CustomDivider,
  InputField,
  UploadImage,
} from '../../components';
import { Download } from '../../assets';
import CurlRequestGenerator from '../dashboard/utils';
import { onscreenTestHeaders } from '../../services/constants';
import { createDigiLockerUrl } from '../../services/onscreenApiService';

export function Combinator({ queryParamsFlag, apiInput, apiUrl, apiResponse, file, setFile, jsonData, setJsonData, image, setImage, status, setStatus }) {
  const theme = useTheme();
  const [loader, setloader] = useState(false);
  const [downloadedUrl, setDownloadedUrl] = useState('');
  const [textCopied, setTextCopied] = useState(false);
  const [errorObject, setErrorObject] = useState(null);
  const [curlUrl, setCurlUrl] = useState(''); 
  const [curlMethod, setCurlMethod] = useState('');
  const [curlBody, setCurlBody] = useState(''); 
  const [requestId, setRequestId] = useState('');
  const { text, upload } = apiInput?.[0] || {};

  const hostname = window.location.hostname;
  
  const onscreenApiUrl =  
  (hostname === "localhost" || hostname === "finhub-stage.habilelabs.io")
  ? 'https://api-gate-stage.finhub.habilelabs.io' : 'https://api-gate.finhub.habilelabs.io';

  const handleDownload = () => {
    if (apiResponse === 'image' && downloadedUrl) {
      window.open(downloadedUrl, '_blank');
    }
  };

  const handleCopyText = () => {
    if (apiResponse === 'json' && jsonData) {
      const jsonString = JSON.stringify(jsonData, null, 2);
      navigator.clipboard.writeText(jsonString)
        .then(() => setTextCopied(true))
        .catch((error) => console.error('Failed to copy text: ', error));
    }
  };

  useEffect(() => {
    setImage((prev) => ({
      ...prev,
      uploaded: null,
      masked: null,
    }));
    setJsonData(null);
    setTextCopied(false);
  }, [apiInput]);

  useEffect(() => {
    if (apiResponse === 'json') {
      setTextCopied(false);
    }
  }, [apiResponse]);

  const handleCreateUrl = async () => {
    const response = await createDigiLockerUrl();
    if (response.status === 200) {
      const url = response.data.result.url;
      const requestId = response.data.result.requestId;
      setRequestId(requestId);
      window.open(url);
    }
  } 

  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Stack sx={{ flex: 1, px: 2, py: 2 }}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h4" sx={{ color: theme.palette.grey.darken4, fontSize: { xs: '1.25rem', md: '1.5rem' } }}>
            Input
          </Typography>

          <CurlRequestGenerator
            method={curlMethod} 
            url={`${onscreenApiUrl}${curlUrl}`}
            headers={onscreenTestHeaders()}
            body={curlBody}
            apiUrl={apiUrl}
            queryParamsFlag={queryParamsFlag}
          />
        </Box>

        {upload?.length > 0 && (
          <UploadImage
            setStatus={setStatus}
            apiResponse={apiResponse}
            upload={upload}
            setImage={setImage}
            setloader={setloader}
            setJsonData={setJsonData}
            setDownloadedUrl={setDownloadedUrl}
            image={image}
            apiUrl={apiUrl}
            errorObject={errorObject}
            setErrorObject={setErrorObject}
            file={file}
            setFile={setFile}
            setCurlMethod={setCurlMethod}
            setCurlUrl={setCurlUrl}
            setCurlBody={setCurlBody}
          />
        )}

        {apiUrl && apiUrl.includes('/s1/digi') ?  <Button sx={{ width: 'fit-content' }} variant="contained" onClick={handleCreateUrl}>
        Digilocker Consent
      </Button> : null}
        {text?.length > 0 && (
          <InputField
            queryParamsFlag={queryParamsFlag}
            setStatus={setStatus}
            text={text}
            setImage={setImage}
            setloader={setloader}
            setJsonData={setJsonData}
            apiUrl={apiUrl}
            errorObject={errorObject}
            setErrorObject={setErrorObject}
            setCurlUrl={setCurlUrl}
            setCurlMethod={setCurlMethod}
            setCurlBody={setCurlBody}
            requestId={requestId}
          />
        )}
      </Stack>
      <CustomDivider dividerColor={theme.palette.background.default} />
      <Stack sx={{ flex: 1, px: 2, py: 2, width: {xs: "100%", md: "50%"} }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <Typography variant="h4" sx={{ color: theme.palette.grey.darken4, mb: { xs: 1, sm:0, md: 0 } }}>
            Response
          </Typography>
          {apiResponse === 'json' ? (
            <IconButton
              onClick={handleCopyText}
              style={{ cursor: 'pointer' }}
            >
              <ContentCopyIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleDownload}
              style={{ cursor: 'pointer' }}
            >
              <img src={Download} alt="Download" />
            </IconButton>
          )}
        </Box>
        <ApiResponse
          apiResponse={apiResponse}
          image={image}
          loader={loader}
          errorObject={errorObject}
          response={{
            image,
            text: jsonData,
            status,
          }}
        />
      </Stack>
    </Stack>
  );
}
